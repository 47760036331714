import React, {FC, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import cn from "classnames";
import {Link} from "react-router-dom";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import {isEqual} from "lodash";

import {TypedDispatch} from "../../../types";
import {
    fetchUserSettings, hasChangedSelector,
    IPreparedOptionsRequest, saveLoadingSelector,
    saveSuccessSelector,
    saveUserSettings, setHasChanged, setSettingsBackUrl, userSettingsSelector,
} from "../../../redux/userSettingsSlice";
import {themeSelector} from "../../../redux/commonSlice";
import {DEFAULT_CARD_LIST, parseData, previewOptions} from "../constants";

import {ICard} from "../SettingsViewPage/SettingsViewPage";
import RadioButton from "../../UI/Input/RadioButton";
import BaseButton from "../../UI/Button/BaseButton";
import Spinner from "../../UI/Spinner/Spinner";

import styles from "./ViewSettingsBlock.module.scss";

interface IViewSettingsBlockProps {
    previewMode?: boolean,
    // eslint-disable-next-line no-unused-vars
    setCardList: (arr: ICard[]) => void,
    cardList: ICard[],
    isAdmin?: boolean,
}

const presets = [
    {label: "По умолчанию", value: "defaultPreset"},
    {label: "Инциденты и статистика", value: "incidentsStatistics"},
    {label: "Карта на весь экран", value: "fullScreenMap"},
];

const ViewSettingsBlock:FC<IViewSettingsBlockProps> = ({previewMode, cardList, setCardList, isAdmin}) => {

    const dispatch = useDispatch<TypedDispatch>();
    const saveSuccess = useSelector(saveSuccessSelector);
    const theme = useSelector(themeSelector);
    const userSettings = useSelector(userSettingsSelector);
    const hasChanged = useSelector(hasChangedSelector);
    const saveLoading = useSelector(saveLoadingSelector);

    useEffect(() => {
        // Получение сохраненных настроек
        if (!userSettings) dispatch(fetchUserSettings());
    }, []);

    useEffect(() => {
        parseData(userSettings, setCardList);
    }, [userSettings]);

    // Переключение видимости превью карточки
    const toggleCardVisibility = (id: number) => {
        const currentCard = cardList.find((item: ICard) => item.id === id);

        if (currentCard) {
            const currentCardValue = currentCard.isVisible;
            // создание нового currentCard объекта для избежания мутации
            const newObj = Object.assign({}, currentCard);
            newObj.isVisible = !currentCardValue;

            const newCardList = cardList.map((card: ICard) => {
                if (card.id === newObj.id) {
                    return newObj;
                }
                return card;
            });
            setCardList(newCardList);

            if (previewMode) {
                updateSettingsOptions(newCardList);
            }
        }
    };

    // Переключение статуса настройки
    const isActiveOption = (id: number) => cardList.filter((card: ICard) => card.id === id)?.[0]?.isVisible;

    // сохранение настроек на бэке
    const updateSettingsOptions = (arr: ICard[]) => {
        const preparedData: IPreparedOptionsRequest = {};

        arr.forEach((card, i) => {
            preparedData[i] = card;
        });

        dispatch(saveUserSettings(preparedData));
        if (hasChanged && !previewMode) dispatch(setHasChanged(false));
    };

    useEffect(() => {
        if (previewMode && saveSuccess) {
            dispatch(fetchUserSettings());
        }
    }, [saveSuccess]);

    const setPreset = (e: React.MouseEvent) => {
        const target = e.target as HTMLInputElement;
        if (!hasChanged) dispatch(setHasChanged(true));
        const value = target.value;
        if (value === presets[0].value) {
            setCardList(DEFAULT_CARD_LIST);
            previewMode && updateSettingsOptions(DEFAULT_CARD_LIST);
        } else if (value === presets[1].value) {
            const preparedArr: ICard[] = [];
            cardList.forEach(((item, i) => {
                preparedArr.push({...item, isVisible: i === 0 || i === 2});
            }));
            setCardList(preparedArr);
            previewMode && updateSettingsOptions(preparedArr);
        } else if (value === presets[2].value) {
            const preparedArr: ICard[] = [];
            cardList.forEach((item => {
                preparedArr.push({...item, isVisible: item.id === 2});
            }));
            setCardList(preparedArr);
            previewMode && updateSettingsOptions(preparedArr);
        }
    };

    const setDefaultChecked = (value: string) => {
        let isChecked: boolean;
        // временное удаление 5-ого виджета (стримов)
        cardList.length = 4;
        switch (value) {
            case presets[0].value: {
                isChecked = isEqual(cardList, DEFAULT_CARD_LIST);
                break;
            }
            case presets[1].value: {
                const setDefValue = () => {
                    const arr = [];
                    for (let i = 0; i < cardList.length; i++) {
                        if (i === 0 || i === 2) {
                            arr.push(cardList[i].isVisible);
                        } else {
                            arr.push(!cardList[i].isVisible);
                        }
                    }
                    return arr;
                };
                isChecked = !setDefValue().some(item => !item);
                break;
            }
            case presets[2].value: {
                const setDefValue = () => {
                    const arr = [];
                    for (let i = 0; i < cardList.length; i++) {
                        if (i === 1) {
                            arr.push(cardList[i].isVisible);
                        } else {
                            arr.push(!cardList[i].isVisible);
                        }
                    }
                    return arr;
                };
                isChecked = !setDefValue().some(item => !item);
                break;
            }
            default:
                isChecked = false;
        }
        return isChecked;
    };

    const setSettingsBackUrlHandler = () => {
        dispatch(setSettingsBackUrl(window.location.pathname));
    };

    const content = (
        <div className={cn(styles["settings"], {[styles["settings--full"]]: !previewMode})}>
            {!isAdmin && previewMode ? (
                <div className={styles["settings-block"]}>
                    <div className={styles["settings__title"]}>Быстрые настройки</div>
                    <Link to={"/settings/view"}>
                        <BaseButton
                            tag={"button"}
                            className={cn(styles["settings__btn"], "btn btn--menu")}
                            onClick={setSettingsBackUrlHandler}
                        >
                            Все настройки
                        </BaseButton>
                    </Link>
                </div>
            ) : !previewMode ? (
                <div className={styles["settings-header"]}>
                    <h1>Вид главного экрана</h1>
                    <div
                        className={cn(styles["settings-header__btn"], "btn--outline")}
                        onClick={() => updateSettingsOptions(cardList)}
                    >
                        Сохранить
                        {saveLoading && <Spinner />}
                    </div>
                </div>
            ) : <></>}

            <div className={styles["settings-block"]}>
                <div className={styles["settings-block__title"]}>Пресеты</div>
                <p className={styles["settings-block__text"]}>
                    Выберите один из подготовленных пресетов, это автоматически выберет нужные окна интерфейса
                </p>

                {userSettings && presets.map(preset => (
                    <RadioButton
                        key={preset.value}
                        name="preset"
                        label={preset.label}
                        value={preset.value}
                        onClick={setPreset}
                        checked={setDefaultChecked(preset?.value)}
                        readOnly
                    />
                ))}
            </div>

            <div>
                <div className={styles["settings-block__title"]}>Окна интерфейса</div>
                <p className={styles["settings-block__text"]}>
                    Настройте какие окна будут отображаться в системе самостоятельно и нажмите сохранить
                </p>

                <div>
                    {previewOptions(theme).map((item: any) => (
                        <div
                            key={item.id}
                            className={cn(styles["view-item"], {[styles["view-item--active"]]:
                                    isActiveOption(item.id)})}
                            onClick={() => {
                                if (!hasChanged) dispatch(setHasChanged(true));
                                toggleCardVisibility(item.id);
                            }}
                        >
                            <div className={styles["view-item__img"]}>
                                <img src={item.img} alt={"dash-view"}/>
                            </div>
                            <div>
                                <div className={styles["view-item__title"]}>{item.title}</div>
                                <div className={styles["view-item__text"]}>{item.text}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );

    return (
        previewMode ?
            <SimpleBar
                style={{maxHeight: "600px", height: "100%", paddingRight: "20px"}}
            >
                {content}
            </SimpleBar> : content
    );
};

export default ViewSettingsBlock;