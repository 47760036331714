import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";

import {RootState} from "store";

import createHttpRequest from "../utils/http";
import {ApiUrls} from "../constants/urls";
import LastIncidentEventModel from "../models/LastIncidentEventModel";
import IncidentEventInfoModelPagedList from "../models/IncidentEventInfoModelPagedList";
import {buildQuery} from "../utils/query";
import Pagination from "../models/Pagination";
import IncidentEventInfoModel from "../models/IncidentEventInfoModel";
import {DEFAULT_EVENTS_SORT, DEFAULT_PAGE_SIZE} from "../constants/common";
import FixationEventItemModel from "../models/FixationEventItemModel";
import {IncidentEventStatus} from "../models/IncidentEventStatus";


export interface IFixationsFilters {
    routeIds: number[], //Маршрут
    roadObjectIds: number[], //место
    violationIds: number[], //Тип фиксации
    sinceDay: string | null, //дата от
    untilDay: string | null, //дата до
    dateKind: string | null,
}

interface IFixationsSort {
    field: "registrationDateTime",
    direction: "desc" | "asc"
}

interface IFixationsQueryParams extends IFixationsFilters {
    page?: number | null,
    pageSize: number,
    sort: string,
}

interface IInitialState {
    lastFixations: LastIncidentEventModel[],
    fixations: IncidentEventInfoModel[],
    fixation: FixationEventItemModel | null,
    pagination: Pagination,
    filters: IFixationsFilters,
    isLastFixationsLoading: boolean,
    isFixationsLoading: boolean,
    isFixationLoading: boolean,
}

const initialState: IInitialState = {
    lastFixations: [],
    fixations: [],
    fixation: null,
    pagination: {
        page: 1,
        firstPage: true,
        lastPage: true,
        pageSize: DEFAULT_PAGE_SIZE,
        totalElements: 0,
        totalPages: 1,
        sort: "registrationDateTime,desc",
    },
    filters: {
        routeIds: [],
        roadObjectIds: [],
        violationIds: [],
        sinceDay: null,
        untilDay: null,
        dateKind: null,
    },
    isLastFixationsLoading: false,
    isFixationsLoading: false,
    isFixationLoading: false,
};

export const fetchLastFixations = createAsyncThunk<LastIncidentEventModel[]>("fixation/fetchLastFixations", async() => {
    const response = await createHttpRequest({
        method: "GET",
        path: ApiUrls.LAST_FIXATIONS,
        errorMessage: "messages:fetch_last_fixations_error",
    });

    return response.data;
});

export const fetchFixations =
    createAsyncThunk<IncidentEventInfoModelPagedList>("fixation/fetchFixations", async(args, {getState}) => {
        const path = buildQuery(ApiUrls.FIXATIONS, getQueryParams(getState() as RootState));
        const response = await createHttpRequest({
            method: "GET",
            path,
            errorMessage: "messages:fetch_fixations_error",
        });

        return response.data;
    });

export const fetchFixation = createAsyncThunk<FixationEventItemModel, string>("event/fetchFixation",
    async(id) => {
        const response = await createHttpRequest({
            method: "GET",
            path: ApiUrls.FIXATION(id),
            errorMessage: "messages:fetch_fixation_error",
        });

        return response.data;
    });

function getQueryParams(rootState: RootState): IFixationsQueryParams {
    const state = rootState.fixationReducer;
    return getQueryParamsFromState(state);
}

function getQueryParamsFromState(state: IInitialState): IFixationsQueryParams {
    return {
        page: state.pagination.page,
        pageSize: state.pagination.pageSize ?? DEFAULT_PAGE_SIZE,
        sort: state.pagination.sort ?? "",
        ...state.filters,
    };
}

function setStats(fixation: FixationEventItemModel): void {
    fixation.stats = fixation.fixation?.routeFixations?.reduce((acc, item) => {
        switch (item.status) {
            case IncidentEventStatus.Processing:
                acc.processing += 1;
                break;
            case IncidentEventStatus.Resolved:
                acc.resolved += 1;
                break;
            case IncidentEventStatus.Rejected:
                acc.rejected += 1;
                break;
            case IncidentEventStatus.New:
                acc.new += 1;
                break;
        }

        if (item.fixation?.vie === true) {
            acc.interval += 1;
        }

        if (item.fixation?.vce === true) {
            acc.pplenter += 1;
        }

        if (item.fixation?.vps === true) {
            acc.pplstaying += 1;
        }

        if (item.fixation?.vete === true || item.fixation?.vete2 === true) {
            acc.arrival += 1;
        }

        return acc;
    }, {processing: 0, rejected: 0, resolved: 0, new: 0, interval: 0, pplenter: 0, pplstaying: 0, arrival: 0});
}

const fixation = createSlice({
    reducers: {
        setPagination(state, {payload}) {
            state.pagination = {
                ...state.pagination,
                ...payload,
            };
        },
        setFilters(state, {payload}) {
            const filtersItem = state.filters?.[payload.filterType as keyof typeof state.filters];
            const nextValue = typeof payload.selectedFilters === "string"
                ? payload.selectedFilters
                : JSON.stringify(payload.selectedFilters);
            const currentValue = typeof filtersItem === "string" ? filtersItem : JSON.stringify(filtersItem);

            if (nextValue !== currentValue) {
                state.filters = {
                    ...state.filters,
                    [payload.filterType]: payload.selectedFilters,
                };
            }
        },
        setInitQueryParams(state, {payload: {stateUpdateFields, params}}) {
            // Если нужно обновить данные в нескольких местах, например, filters и pagination
            stateUpdateFields?.forEach((field: keyof typeof state) => {
                const stateField = state[field] as keyof IInitialState;
                const newState: keyof IInitialState | {[key: string]: any} = {};
                // Перебираем переданные квери параметры и заменяем значения в существующих полях стейт
                Object.entries(params).forEach((([paramKey, paramValue]) => {
                    const key = paramKey as keyof IFixationsFilters;
                    const value = paramValue as string;
                    if (Object.prototype.hasOwnProperty.call(stateField, key)) {
                        // @ts-ignore
                        stateField[key] && Array.isArray(stateField[key])
                            ? newState[key] = value.split(",")
                            : newState[key] = value;
                    }
                }));

                if (Object.keys(newState).length) {
                    (state[field] as IInitialState) = {
                        ...state[field] as IInitialState,
                        ...newState,
                    };
                }
            });
        },
    },
    name: "fixation",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchLastFixations.pending, (state) => {
            state.lastFixations = [];
            state.isLastFixationsLoading = true;
        });
        builder.addCase(fetchLastFixations.fulfilled, (state, {payload}) => {
            state.lastFixations = payload;
            state.isLastFixationsLoading = false;
        });
        builder.addCase(fetchLastFixations.rejected, (state) => {
            state.lastFixations = [];
            state.isLastFixationsLoading = false;
        });

        builder.addCase(fetchFixations.pending, (state) => {
            state.isFixationsLoading = true;
        });
        builder.addCase(fetchFixations.fulfilled, (state, {payload}) => {
            state.isFixationsLoading = false;
            state.fixations = payload.data;
            state.pagination = payload.pagination;
        });
        builder.addCase(fetchFixations.rejected, (state) => {
            state.isFixationsLoading = false;
        });

        builder.addCase(fetchFixation.pending, (state) => {
            state.isFixationLoading = true;
        });
        builder.addCase(fetchFixation.fulfilled, (state, {payload}) => {
            state.isFixationLoading = false;
            setStats(payload);
            state.fixation = payload;
        });
        builder.addCase(fetchFixation.rejected, (state) => {
            state.isFixationLoading = false;
        });
    },
});


export default fixation.reducer;

export const {setPagination, setFilters, setInitQueryParams} = fixation.actions;

//Селекторы
const slice = ({fixationReducer}: RootState) => fixationReducer;

export const lastFixationsSelector = createSelector(
    slice,
    ({lastFixations}) => lastFixations,
);

export const fixationsSelector = createSelector(
    slice,
    ({fixations}) => fixations,
);

export const fixationSelector = createSelector(
    slice,
    ({fixation}) => fixation,
);

export const isLastFixationsLoadingLoadingSelector = createSelector(
    slice,
    ({isLastFixationsLoading}) => isLastFixationsLoading,
);

export const isFixationsLoadingSelector = createSelector(
    slice,
    ({isFixationsLoading}) => isFixationsLoading,
);

export const isFixationLoadingSelector = createSelector(
    slice,
    ({isFixationLoading}) => isFixationLoading,
);

export const fixationsPaginationSelector = createSelector(
    slice,
    ({pagination}) => pagination,
);

export const fixationsFiltersSelector = createSelector(
    slice,
    ({filters}) => filters,
);

export const fixationsSortSelector = createSelector(
    slice,
    ({pagination}):IFixationsSort => {
        const[field, direction] = (pagination?.sort ?? DEFAULT_EVENTS_SORT).split(",");
        return {field, direction} as IFixationsSort;
    },
);

export const fixationsPageSelector = createSelector(
    slice,
    ({pagination}) => pagination.page,
);

export const downloadLinkSelector = createSelector(
    slice,
    (state) => buildQuery(
        ApiUrls.FIXATIONS_DOWNLOAD,
        getQueryParamsFromState(state)
    ),
);
